import React, { useEffect } from "react";

const dev = process.env.NODE_ENV !== "production";
const withAnalytics = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> => {
  const WithAnalyticsHOC: React.FC = (props) => {
    useEffect(() => {
      if (dev) {
        console.info("Not adding analytics as in development mode!");
        return;
      }
      // only on prod mount

      // Matomo
      const _paq = (window["_paq"] = window["_paq"] || []);
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
      let g;
      (function () {
        const u = "//analytics.purpleradio.co.uk/";
        _paq.push(["setTrackerUrl", u + "matomo.php"]);
        _paq.push(["setSiteId", "1"]);
        const d = document;
        g = d.createElement("script");
        const s = d.getElementsByTagName("script")[0];
        g.type = "text/javascript";
        g.async = true;
        g.src = u + "matomo.js";
        s.parentNode.insertBefore(g, s);
      })();
      // End Matomo

      return () => {
        delete window["_paq"];
        if (g) {
          g.remove();
        }
      };
    }, []);

    return <WrappedComponent {...(props as P)} />;
  };
  WithAnalyticsHOC.displayName = `withAnalytics(${WrappedComponent.displayName})`;
  return WithAnalyticsHOC;
};

export default withAnalytics;
