import React, { useEffect } from "react";

import { RootState, wrapper } from "@/store/store";

import { useDispatch, useSelector } from "react-redux";
import Script from 'next/script';
import { signinOnLoad } from "@/store/auth/action";
import { compose } from "redux";

// import("@/styles/antd.less);
require("@/styles/base.less");
import type { AppProps } from "next/app";
import withAnalytics from "@/utils/hocs/withAnalytics";
import { isServer } from "@/utils/general";
import { DISABLE_SSR_TRANSITION } from "./_document";

const dev = process.env.NODE_ENV !== "production";

const App = ({ Component, pageProps }: AppProps) => {
  // get user role from redux state
  const role = useSelector<RootState>(({ auth }) => auth.role);
  const dispatch = useDispatch();
  useEffect(() => {
    // a redux action that sends a one - time request to "/user/signed" on initial page load
    // which sets a role as "guest" or "member" when resolved
    // since this is a client - side request, the cookie logic isn't needed
    if (!role) dispatch(signinOnLoad());
  }, [dispatch, role]);

  useEffect(() => {
    // avoid CSS animation flashing
    if (!isServer()) {
      const disableTransitionDom = document.getElementById(
        DISABLE_SSR_TRANSITION
      );

      if (disableTransitionDom) disableTransitionDom.remove();
    }
  }, []);

  return (
  <>
    <Script strategy="afterInteractive" async defer data-website-id="6fca1579-1e32-41e9-9ce2-2bbf65003135" src="https://beta.purpleradio.co.uk/umami.js"/>
    <Component {...pageProps} />
  </>
  )
};

// Wraps all components in the tree with the data provider
export default compose(wrapper.withRedux, withAnalytics)(App);
