import _cloneDeep from "lodash/cloneDeep";

const LOCAL_STORAGE_KEY = "saved-state" as const;

const EXPIRY_TIME = 60 * 60 * 24 * 1000;

// localStorage.js
export const saveState = (state) => {
  try {
    const stateToSave = _cloneDeep(state);

    stateToSave.__SAVE_DATE = +new Date();

    const serializedState = JSON.stringify(stateToSave);

    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
  } catch {
    // ignore write errors
  }
};

// localStorage.js
export const loadState = () => {
  if (
    typeof window === "undefined" ||
    typeof window["localStorage"] === "undefined"
  ) {
    return undefined;
  }
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }

    const { __SAVE_DATE, ...acState } = JSON.parse(serializedState);

    const savedDate = parseFloat(__SAVE_DATE);

    if (savedDate == null) {
      return undefined;
    }
    const milliDiff = +new Date() - savedDate;

    // expires after 24 hours
    if (milliDiff > EXPIRY_TIME) {
      return undefined;
    }
    return acState;
  } catch (err) {
    return undefined;
  }
};
