import { liveRadioActionTypes } from "../liveRadio/action";
import _includes from "lodash/includes";
import _fromPairs from "lodash/fromPairs";

/**
 *
 * Object lookup of redux actions to catch and report as events
 */
const CAUGHT_ACTIONS: Record<string, string[]> = _fromPairs([
  [liveRadioActionTypes.PLAY_RADIO, ["RadioWidget", "Play"]],
  [liveRadioActionTypes.PAUSE_RADIO, ["RadioWidget", "Pause"]],
]);

export const analyticsMiddleware = (storeAPI) => (next) => (action) => {
  if (typeof window === "undefined" || typeof window["_paq"] === "undefined") {
    return next(action);
  }

  try {
    if (_includes(Object.keys(CAUGHT_ACTIONS), action.type)) {
      window["_paq"].push(["trackEvent", ...CAUGHT_ACTIONS[action.type]]);
    }
  } catch (e) {
    console.error("Failed to log analytics with middleware", e);
  }

  return next(action);
};
