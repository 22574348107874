import { Dispatch } from "redux";

export const djMessageFormActionTypes = {
  SET_DJ_MESSAGE_FORM_VISIBILITY: "SET_DJ_MESSAGE_FORM_VISIBILITY",
};

export const setDJFormVisibility = (visibility: boolean) => (
  dispatch: Dispatch
) => {
  return dispatch({
    type: djMessageFormActionTypes.SET_DJ_MESSAGE_FORM_VISIBILITY,
    payload: visibility,
  });
};
