import { Dispatch } from "redux";
import { RadioMetadata } from "./reducer";

type ValueOf<T> = T[keyof T];

export const liveRadioActionTypes = {
  PLAY_RADIO: "PLAY_RADIO",
  PAUSE_RADIO: "PAUSE_RADIO",
  SET_RADIOFEED_STATE: "SET_RADIOFEED_STATE",
  UPDATE_METADATA: "UPDATE_METADATA",
  SET_VOLUME: "SET_VOLUME",
};

export const RADIO_FEED_STATE = {
  SHOWN: "SHOWN",
  MINIMAL: "MINIMAL",
  HIDDEN: "HIDDEN",
};

export const RADIO_STATE = {
  PLAYING: "PLAYING",
  PAUSED: "PAUSED",
};

export const playRadio = () => (dispatch: Dispatch) => {
  return dispatch({ type: liveRadioActionTypes.PLAY_RADIO });
};

export const pauseRadio = () => (dispatch: Dispatch) => {
  return dispatch({ type: liveRadioActionTypes.PAUSE_RADIO });
};

export const setVolume = (newVolume) => (dispatch: Dispatch) => {
  return dispatch({
    type: liveRadioActionTypes.SET_VOLUME,
    payload: newVolume,
  });
};

export const setRadioFeedState = (
  newRadioFeedState: ValueOf<typeof RADIO_FEED_STATE>
) => (dispatch: Dispatch) => {
  return dispatch({
    type: liveRadioActionTypes.SET_RADIOFEED_STATE,
    payload: newRadioFeedState,
  });
};

export const radioMetadataUpdate = (newMetadata: RadioMetadata) => (
  dispatch: Dispatch
) => {
  return dispatch({
    type: liveRadioActionTypes.UPDATE_METADATA,
    payload: newMetadata,
  });
};
