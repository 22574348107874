import { liveRadioActionTypes, RADIO_FEED_STATE, RADIO_STATE } from "./action";

import merge from "lodash/merge";

export type ValueOf<T> = T[keyof T];

export interface Song {
  duration: number;
  text: string;
  artist: string;
  title: string;
  album: string;
  art: string;
  playlist: string;
  cued_at: number;
}

export interface RadioMetadata {
  enqueuedSongs?: Song[];
  listeners?: number;
  audioURL?: string;
}

export interface LiveRadioState {
  state: ValueOf<typeof RADIO_STATE>;
  radioFeedState: ValueOf<typeof RADIO_FEED_STATE>;
  metadata: RadioMetadata;
  volume: number;
}

const liveRadioInitialState: LiveRadioState = {
  state: RADIO_STATE.PAUSED,
  metadata: {
    enqueuedSongs: [],
    listeners: 0,
    audioURL: null,
  },
  volume: 1,
  radioFeedState: RADIO_FEED_STATE.HIDDEN,
};

export default function reducer(
  state = liveRadioInitialState,
  action
): LiveRadioState {
  switch (action.type) {
    case liveRadioActionTypes.PLAY_RADIO:
      return merge({}, state, {
        state: RADIO_STATE.PLAYING,
      });
    case liveRadioActionTypes.PAUSE_RADIO:
      return merge({}, state, {
        state: RADIO_STATE.PAUSED,
      });
    case liveRadioActionTypes.UPDATE_METADATA:
      return merge({}, state, {
        metadata: merge({}, state.metadata, action.payload),
      });
    case liveRadioActionTypes.SET_VOLUME:
      return merge({}, state, {
        volume: action.payload,
      });
    case liveRadioActionTypes.SET_RADIOFEED_STATE:
      return merge({}, state, {
        radioFeedState: action.payload,
      });
    default:
      return state;
  }
}
