const API_URL = process.env.NEXT_PUBLIC_API_URL || "/api";

class LoginUtils {
  static async postFetch(
    apiUrl: string,
    body: unknown,
    config?: Record<string, unknown>
  ): Promise<Response> {
    if (typeof window === "undefined") {
      throw new Error("Server sent request?");
    }

    return fetch(API_URL + apiUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit}
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      ...config,
    });
  }
  static async getFetch(
    apiUrl: string,
    authJWT: string,
    config?: Record<string, unknown>
  ): Promise<Response> {
    if (typeof window === "undefined") {
      throw new Error("Server sent request?");
    }

    return fetch(API_URL + apiUrl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit}
      headers: {
        Authorization: "Bearer " + authJWT,
      },
      ...config,
    });
  }

  static async getLoggedIn(): Promise<Record<string, unknown> | null> {
    if (typeof window === "undefined" || typeof localStorage === "undefined") {
      return null;
    }
    const storedJWT = localStorage.getItem("jwt");
    if (storedJWT == null) {
      return null;
    }

    const fetchData = await LoginUtils.getFetch("/users/me", storedJWT);

    if (!fetchData.ok) {
      return null;
    }

    return fetchData.json();
  }

  static async login({
    identifier,
    password,
  }: {
    identifier: string;
    password: string;
  }): Promise<boolean> {
    if (typeof window === "undefined") {
      throw new Error("Server sent login?");
    }
    let authData;
    try {
      const fetchData = await LoginUtils.postFetch("/auth/local", {
        identifier,
        password,
      });

      if (!fetchData.ok) {
        throw new Error("Something went wrong!");
      }

      authData = await fetchData.json();
    } catch (e) {
      return false;
    }

    localStorage.setItem("jwt", authData.jwt);

    return true;
  }

  static getToken(): string | null {
    if (typeof window === "undefined") {
      throw new Error("Server sent Token get?");
    }
    return localStorage.getItem("jwt");
  }

  static logout(): void {
    if (typeof window === "undefined") {
      throw new Error("Server sent logout?");
    }
    localStorage.removeItem("jwt");
  }
}
export default LoginUtils;
