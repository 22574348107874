import { Dispatch } from "redux";
import LoginUtils from "../../utils/auth/LoginUtils";

export const AUTH_ACTIONS = {
  SIGNIN_ON_LOAD: "SIGNIN_ON_LOAD",
};

export const signinOnLoad = () => (dispatch: Dispatch) => {
  return dispatch({
    type: AUTH_ACTIONS.SIGNIN_ON_LOAD,
    payload: LoginUtils.getLoggedIn(),
  });
};
