// import {  } from "./action";

import merge from "lodash/merge";
import { pageInfoActionTypes } from "./action";

export interface PageInfoState {
  visible: boolean;
}

const pageInfoInitialState: PageInfoState = {
  visible: true,
};

export default function reducer(
  state = pageInfoInitialState,
  action
): PageInfoState {
  switch (action.type) {
    default:
      return state;
  }
}
