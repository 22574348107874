import { createStore, applyMiddleware, combineReducers } from "redux";
import { HYDRATE, createWrapper } from "next-redux-wrapper";
import thunkMiddleware from "redux-thunk";
import liveRadio, { LiveRadioState } from "./liveRadio/reducer";
import pageInfo, { PageInfoState } from "./pageInfo/reducer";
import auth, { AuthState } from "./auth/reducer";
import promiseMiddleware from "redux-promise";

import { analyticsMiddleware } from "./middleware/analyticsMiddleware";
import _throttle from "lodash/throttle";
import _merge from "lodash/merge";
import _pick from "lodash/pick";

import djMessageForm, { DJMessageFormState } from "./djMessageForm/reducer";
import { loadState, saveState } from "./utils/localStore";

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export interface RootState {
  liveRadio: LiveRadioState;
  pageInfo: PageInfoState;
  auth: AuthState;
  djMessageForm: DJMessageFormState;
}

const combinedReducer = combineReducers({
  liveRadio,
  pageInfo,
  auth,
  djMessageForm,
  // count,
  // tick,
});

const rootReducer = (state, action) => {
  // SSR hydration
  if (action.type === HYDRATE) {
    const loadedState = loadState();
    console.log(loadedState);
    const nextState = _merge(
      state, // use previous state
      action.payload, // apply delta from hydration
      loadedState // load localStorage state
    );

    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

const initStore = () => {
  const store = createStore(
    rootReducer,
    typeof window !== "undefined" &&
      typeof window["localStorage"] !== "undefined"
      ? loadState()
      : undefined,
    bindMiddleware([promiseMiddleware, thunkMiddleware, analyticsMiddleware])
  );

  /**
   * for now this is fine, but might be nice to delegate to each reducer function for this?
   */
  // saved properties
  const PICKED_TO_SAVE = [
    "liveRadio.state",
    "liveRadio.volume",
    "liveRadio.radioFeedState",
  ] as const;

  // throttle saves
  store.subscribe(
    _throttle(() => {
      const state = store.getState();
      saveState(_pick(state, PICKED_TO_SAVE));
    }, 300)
  );

  return store;
};

export const wrapper = createWrapper(initStore);
