// import {  } from "./action";

import merge from "lodash/merge";
import { djMessageFormActionTypes } from "./action";

export interface DJMessageFormState {
  visible: boolean;
}

const pageInfoInitialState: DJMessageFormState = {
  visible: false,
};

export default function reducer(
  state = pageInfoInitialState,
  action
): DJMessageFormState {
  switch (action.type) {
    case djMessageFormActionTypes.SET_DJ_MESSAGE_FORM_VISIBILITY:
      return merge({}, state, {
        visible: action.payload,
      });
    default:
      return state;
  }
}
