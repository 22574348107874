import merge from "lodash/merge";
import { AUTH_ACTIONS } from "./action";

export type ValueOf<T> = T[keyof T];

export interface AuthState {
  role: string;
  username: string;
}

const AuthInitialState: AuthState = {
  role: null,
  username: null,
};

interface AuthAction {
  type: ValueOf<typeof AUTH_ACTIONS>;
  payload: any;
}

export default function reducer(
  state = AuthInitialState,
  action: AuthAction
): AuthState {
  switch (action.type) {
    case AUTH_ACTIONS.SIGNIN_ON_LOAD:
      if (action.payload == null) {
        return merge({}, state, {
          role: "guest",
          username: null,
        });
      }
      return merge({}, state, {
        role: action?.payload?.role?.type,
        username: action?.payload?.username,
        email: action?.payload?.email,
      });
    default:
      return state;
  }
}
